import React from "react";

import { BiUser } from "react-icons/bi";
import {
  MdDashboard,
} from "react-icons/md";
import { RiArticleFill, RiVideoFill } from "react-icons/ri";
import { IoMdSettings } from "react-icons/io";

export const links = [
  {
    title: "sidebar.dashboard",
    links: [
      {
        type: "route",
        name: "sidebar.dashboard",
        path: "/dashboard",
        icon: <MdDashboard />,
      },
    ],
  },
  {
    title: "sidebar.myPages",
    links: [
      {
        type: "route",
        name: "sidebar.profile",
        path: "/profile",
        icon: <BiUser />,
      },
      {
        type: "route",
        name: "sidebar.articles",
        path: "/articles",
        icon: <RiArticleFill />,
      },
      {
        type: "route",
        name: "sidebar.programs",
        path: "/programs",
        icon: <RiVideoFill />,
      },
      {
        type: "route",
        name: "sidebar.main",
        path: "/main",
        icon: <IoMdSettings size={22} />,
      },
    ],
  },
];

export const dashboardItems = [
  {
    slug: "articles",
    type: "route",
    name: "sidebar.articles",
    path: "/articles",
    icon: <RiArticleFill color="red" size="40" />,
    color: "red",
  },
    {
      slug: "programs",
      type: "route",
      name: "sidebar.programs",
      path: "/programs",
      icon: <RiVideoFill color="red" size="40" />,
      color: "red",
    },
];
