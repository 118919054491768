import {
  doc,
  getDoc,
  getDocs,
  collection,
  query,
  limit,
  addDoc,
  updateDoc,
  deleteDoc,
  startAfter,
  getCountFromServer,
} from "firebase/firestore";
import { db } from "config/firebase";

const getDocument = (id) => {
  return doc(db, "programs", id);
};

export const getData = async (reference) => {
  if (reference) {
    const docSnap = await getDoc(reference);
    return docSnap.exists ? { id: docSnap?.id, ...docSnap.data() } : null;
  }
  return null;
};

export const getPrograms = async (last) => {
  try {
    // Query to get the programs
    const q = last
      ? query(
          collection(db, "programs"),
          // orderBy("createdAt", "desc"),
          startAfter(last),
          limit(10),
        )
      : query(
          collection(db, "programs"),
          // orderBy("createdAt", "desc"),
          limit(10),
        );

    const documentSnapshots = await getDocs(q);

    // Get the last visible document
    const lastVisible =
      documentSnapshots.docs[documentSnapshots.docs.length - 1];

    const programs = documentSnapshots.docs.map((doc) => {
      return { ...doc.data(), id: doc.id };
    });

    // Get the total count of programs in the collection
    const countQuery = query(collection(db, "programs"));
    const countSnapshot = await getCountFromServer(countQuery);
    const totalProgramsCount = countSnapshot.data().count;
    console.log(totalProgramsCount, "totalProgramsCount");
    // Return programs, last document, and total count of programs
    return { programs, last: lastVisible, totalProgramsCount };
  } catch (error) {
    console.error(`Error while getting programs: ${error}`);
    return error;
  }
};

export const getProgramsLength = async () => {
  const collectionRef = collection(db, "programs");
  const snapshot = await getDocs(collectionRef);
  return snapshot.size;
};

export const getProgramById = async (id) => {
  const docRef = getDocument(id);
  const docSnap = await getDoc(docRef);
  const lesson = docSnap.data();
  return docSnap.exists ? { id: docSnap.id, ...lesson, reviewers: [] } : null;
};

export const createProgram = async (program) => {
  try {
    const newProgram = await addDoc(collection(db, "programs"), program);
    return newProgram;
  } catch (e) {
    console.error(`Error while creating program ${e}`);
    return false;
  }
};

export const updatePrograms = async (id, data) => {
  try {
    await updateDoc(getDocument(id), data);
  } catch (e) {
    console.error(`Error while updating program ${e}`);
    return false;
  }
};

export const deleteProgram = async (id) => {
  try {
    await deleteDoc(getDocument(id));
  } catch (e) {
    console.error(`Error while deleting program ${e}`);
    return false;
  }
};
