import React from "react";
import ProgramList from "components/programs/ProgramList";
import Loading from "components/common/Loading";
import usePrograms from "hooks/programs/usePrograms";
import useAuth from "hooks/useAuth";
import { createProgram } from "api/programs.api";

const Programs = () => {
  const { programs, isLoading, getNextPage, page, setPage, hasMoreItems } =
    usePrograms();
  const { currentUser } = useAuth();
  return isLoading && programs.length === 0 ? (
    <Loading />
  ) : (
    <ProgramList
      programs={programs}
      currentUserId={currentUser?.id}
      currentUserType={currentUser?.type}
      currentUserDisplayName={currentUser?.displayName}
      isLoadingNextPage={isLoading}
      hasMoreItems={hasMoreItems}
      getNextPage={getNextPage}
      page={page}
      setPage={setPage}
      createProgram={createProgram}
    />
  );
};

export default Programs;
