import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getPrograms,
  createProgram,
  updatePrograms,
  deleteProgram,
  getProgramById,
} from "api/programs.api";

export const getProgramsAsync = createAsyncThunk(
  "programs/get-all",
  async () => {
    const data = await getPrograms();
    return data;
  },
);

export const getNextProgramsAsync = createAsyncThunk(
  "programs/get-all-next",
  async (last) => {
    const data = await getPrograms(last);
    return data;
  },
);

export const getProgramByIdAsync = createAsyncThunk(
  "program/get-by-id",
  async (id) => {
    const program = await getProgramById(id);
    const response = await fetch(program.content);
    const text = await response.text();
    return { ...program, content: text };
  },
);

export const createProgramAsync = createAsyncThunk(
  "programs/create",
  async (program) => {
    const programs = await createProgram(program);
    return programs;
  },
);

export const updateProgramAsync = createAsyncThunk(
  "program/update",
  async ({ id, data }) => {
    await updatePrograms(id, data);
  },
);

export const deleteProgramAsync = createAsyncThunk(
  "program/delete",
  async (id) => {
    await deleteProgram(id);
  },
);
