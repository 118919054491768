import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import InfiniteScrollTrigger from "components/infinite-scroll/InfiniteScrollTrigger";
import Card from "components/card/Card";
import { Button } from "@mui/material";
import { skeletonGenerator } from "components/infinite-scroll/helpers";
import ProgramSkeleton from "components/infinite-scroll/skeletons/ArticleSkeleton";

const ProgramList = ({
  programs,
  currentUserId,
  currentUserDisplayName,
  currentUserType,
  isLoadingNextPage,
  hasMoreItems,
  getNextPage,
  page,
  setPage,
  createProgram,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div
      className="w-full h-full overflow-y-auto p-16"
      style={{
        background: "#e2e2e2",
      }}
    >
      <div className="m-auto">
        <div className="w-full grid justify-items-end">
          <Button
            variant="outlined"
            onClick={async () => {
              const program = await createProgram({
                createdAt: Date.now(),
                author: {
                  id: currentUserId,
                  displayName: currentUserDisplayName,
                },
              });
              navigate(`/programs/manage/${program.id}`);
            }}
            className="justify-items-end"
          >
            {t("programs.programList.createNewProgram")}
          </Button>
        </div>
        <div className="p-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 w-full">
          {" "}
          {programs.map(
            ({ title, poster, description, id, author, category }) => {
              return (
                <Card
                  key={id}
                  title={title}
                  image={poster}
                  category={category}
                  path={`/programs/${id}`}
                  description={description}
                  primaryButtonText={t("card.view")}
                  secondaryButtonText={t("card.edit")}
                  onSecondaryButtonClick={() =>
                    navigate(`/programs/manage/${id}`)
                  }
                  onPrimaryButtonClick={() => navigate(`/programs/${id}`)}
                  showSecondaryButton={
                    author?.id === currentUserId ||
                    currentUserType === "admin" ||
                    currentUserType === "manager" ||
                    currentUserType === "editor"
                  }
                  author={author}
                />
              );
            }
          )}
          {isLoadingNextPage && skeletonGenerator(<ProgramSkeleton />)}
          <InfiniteScrollTrigger
            isLoading={isLoadingNextPage}
            hasMoreItems={hasMoreItems}
            callback={getNextPage}
            page={page}
            setPage={setPage}
          />
        </div>
      </div>
    </div>
  );
};

export default ProgramList;
