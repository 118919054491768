import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPrograms,
  selectIsLoading,
  selectLastProgram,
  selectProgramCount,
} from "redux-toolkit/programs/selectors";
import {
  getProgramsAsync,
  getNextProgramsAsync,
} from "redux-toolkit/programs/actions";

const usePrograms = () => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const programs = useSelector(selectPrograms);
  const isLoading = useSelector(selectIsLoading);
  const last = useSelector(selectLastProgram);
  const count = useSelector(selectProgramCount);

  useEffect(() => {
    dispatch(getProgramsAsync());
  }, [dispatch]);

  const getNextPage = () => {
    if (last && count > programs.length) {
      dispatch(getNextProgramsAsync(last));
      setPage(page + 1);
    }
  };

  return {
    programs,
    isLoading,
    hasMoreItems: count > programs.length,
    page,
    setPage,
    getNextPage,
  };
};

export default usePrograms;
