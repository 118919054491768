import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Tooltip as ReactToolTip } from "react-tooltip";
import { TiPin } from "react-icons/ti";
import { RiUnpinFill } from "react-icons/ri";
import { LuLogOut } from "react-icons/lu";
import { Button } from "@mui/material";

import { links } from "../../constants/data";
import { useTranslation } from "react-i18next";

const Sidebar = ({ isExpanded, setIsExpanded, signOut }) => {
  const { t } = useTranslation();
  const isPinnedFromLocalStorage = localStorage.getItem("isPinned") === "true";
  const [isPinned, setIsPinned] = useState(isPinnedFromLocalStorage);
  const activeLink =
    "flex items-center bg-light-gray gap-5 pl-2 py-2 rounded-lg text-gray-800 text-md m-1 text-black bg-gray-200";
  const activeLinkSmall =
    "flex items-center justify-center bg-light-gray p-2 rounded-lg text-gray-800 text-md mt-2 text-black bg-gray-200";
  const normalLink =
    "flex text-slate-50 items-center gap-5 pl-2 py-2 rounded-lg text-md text-gray-300 dark:text-gray-200 dark:hover:text-black hover:bg-gray-200 m-1";
  const normalLinkSmall =
    "flex text-slate-50 items-center justify-center p-2 rounded-lg text-md text-gray-300 dark:text-gray-200 dark:hover:text-black hover:bg-gray-600 mt-2";

  return (
    <div
      onMouseEnter={() => {
        !isPinned && setIsExpanded(true);
      }}
      onMouseLeave={() => {
        !isPinned && setIsExpanded(false);
      }}
      className={`hidden md:block px-2 h-screen overflow-y-auto overflow-x-hidden pb-10 bg-gradient-to-b from-[#1c1a17] to-[#3a2005] bg-opacity-20 dark:bg-secondary-dark-bg transition-all duration-300 ${
        isExpanded ? "w-56" : "w-24"
      }`}
    >
      <div className="flex items-center justify-center mt-4">
        <span className="capitalize text-lg text-white">ԱՐԴԱՐ</span>
      </div>
      <div
        onClick={() => {
          setIsPinned(!isPinned);
          localStorage.setItem("isPinned", !isPinned);
        }}
        className={`flex ${isExpanded ? "justify-end" : "justify-center"}`}
      >
        {isExpanded && (
          <button
            id="sidebar-button"
            type="button"
            aria-label="sidebar-button"
            data-type={isExpanded ? "light" : "dark"}
            className="absolute top-3 right-2 cursor-pointer p-2 text-2xl rounded-full z-50" // text-purple-100 hover:text-[#6051bb] rounded-full p-3 hover:bg-light-gray mt-4 ml-2 block"
            onClick={() => {
              setIsPinned(!isPinned);
              localStorage.setItem("isPinned", !isPinned);
            }}
          >
            {!isPinned ? (
              <TiPin color="white" />
            ) : (
              <RiUnpinFill color="white" />
            )}
          </button>
        )}
        <ReactToolTip
          anchorSelect="#sidebar-button"
          effect="solid"
          content={isExpanded ? t("sidebar.shrink") : t("sidebar.expand")}
          variant="dark"
          place="right"
          style={{ zIndex: 999 }}
        />
      </div>

      <div className="mt-4 h-full flex flex-col justify-between">
        <div>
          {links.map((item) => (
            <div key={item.title}>
              <p
                className={`text-gray-400 text-sm uppercase ${
                  isExpanded
                    ? "opacity-100 w-fit h-fit transition-opacity ease-in-out delay-150 duration-500"
                    : "opacity-0 h-0 w-0"
                }`}
              >
                {t(item.title)}
              </p>
              {!isExpanded && (
                <div className="m-3">
                  <div className="border-b-1 border-gray-500" />
                </div>
              )}
              {item.links.map((link) => {
                return link.type === "route" ? (
                  <NavLink
                    to={link.path}
                    key={link.name}
                    className={({ isActive }) => {
                      if (isActive) {
                        return isExpanded ? activeLink : activeLinkSmall;
                      } else {
                        return isExpanded ? normalLink : normalLinkSmall;
                      }
                    }}
                  >
                    {link.icon}
                    <span
                      className={`capitalize text-sm ${
                        isExpanded
                          ? "opacity-100 w-fit h-fit transition-opacity ease-in-out delay-150 duration-500"
                          : "opacity-0 h-0 w-0"
                      }`}
                    >
                      {t(link.name)}
                    </span>
                  </NavLink>
                ) : (
                  <a
                    href={link.path}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={isExpanded ? normalLink : normalLinkSmall}
                    key={link.name}
                  >
                    <span className="text-xl">{link.icon}</span>
                    <span
                      className={`capitalize text-sm ${
                        isExpanded
                          ? "opacity-100 w-fit h-fit transition-opacity ease-in-out delay-150 duration-500"
                          : "opacity-0 h-0 w-0"
                      }`}
                    >
                      {t(link.name)}
                    </span>
                  </a>
                );
              })}
            </div>
          ))}
        </div>
        <div className="flex items-center justify-center gap-4 mb-10">
          <Button onClick={signOut} variant="contained" color="primary">
            <LuLogOut color="#fff" />
            {isExpanded && (
              <span className="text-white text-sm ml-4">
                {t("sidebar.signOut")}
              </span>
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
